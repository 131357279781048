import { AgentSelector } from '@/aiAssistant/AgentSelector.atom';
import { MarkdownSnippet } from '@/aiAssistant/MarkdownSnippet.molecule';
import {
  Agent,
  agents,
  AgentType,
  assistantLocations,
  AssistantOrigin,
  ChatMessage,
} from '@/aiAssistant/aiAssistant.types';
import { Icon } from '@seeqdev/qomponents';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Feedback } from '@/aiAssistant/feedback/Feedback.atom';
import { StatusUpdate } from '@/aiAssistant/StatusUpdates.atom';
import { CopyButtonGivenText } from '@/core/CopyButtonGivenText.atom';
import { sqAiAssistantStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';

interface MessageProps {
  message: ChatMessage;
  insertFormulaSnippet: (snippet: string) => void;
  last?: boolean;
  isRunning: boolean;
  selectedAgent?: AgentType;
  onAgentChange?: (agent: AgentType) => void;
  submitPrompt: (prompt: string, origin: AssistantOrigin, context?: Record<string, unknown>) => void;
  origin: AssistantOrigin;
  scrollToBottom: () => void;
}

const getStyleByOrigin = (origin: AssistantOrigin) => {
  switch (origin) {
    case 'workbench':
      return 'analysis';
    case 'datalab':
      return 'datalab';
    default:
      return 'seeq-blue';
  }
};

const getOriginLabel = (message: ChatMessage) => {
  if (message.origin) {
    return `${assistantLocations[message.origin]} ${message.originDetails?.label ?? ''}`;
  }
  return '';
};

const Question: React.FunctionComponent<{ message: ChatMessage }> = ({ message }) => {
  return (
    <div key={message.id} data-testid="message" className={`flexRowContainer pt15 pr15 pl15 pb5 ${message.role}`}>
      <div className="flexColumnContainer">
        <Icon icon="fa-user" testId="fa-user" extraClassNames="pl5 pt3 pr10" />
        <div className="flexFill messageWithTags">
          <div className="flexFill messageContent">
            <MarkdownSnippet markdown={message.dialog} insertFormulaSnippet={_.noop} />
          </div>

          {message.origin && (
            <div className="flexColumnContainer flexAlignCenter flexFill">
              <div className="flexFill" />
              <div className={`tags ${getStyleByOrigin(message.origin)}`}>{getOriginLabel(message)}</div>
            </div>
          )}
        </div>
        <CopyButtonGivenText
          textToCopy={message.dialog}
          extraClassNames="flexRowContainer"
          tooltip="AI_ASSISTANT.COPY_QUESTION.TO_CLIPBOARD"
          notifyMessage="AI_ASSISTANT.COPY_QUESTION.SUCCESS"
        />
      </div>
    </div>
  );
};

export const Message: React.FunctionComponent<MessageProps> = ({
  isRunning,
  message,
  insertFormulaSnippet,
  last,
  selectedAgent,
  onAgentChange,
  origin,
  submitPrompt,
  scrollToBottom,
}) => {
  const [agent, setAgent] = useState<Agent>(_.find(agents, { key: selectedAgent })!);
  const { t } = useTranslation();

  const messages = useFluxPath(sqAiAssistantStore, () => sqAiAssistantStore.messages);
  // TEMPORARY: For right now we don't get an agent returned from the server - so fake it until you make it!
  if (!agent && !selectedAgent) {
    const fakeAgent = _.sample(agents);
    message.agent = fakeAgent!.key;
    setAgent(fakeAgent!);
  }

  return message.role === 'user' ? (
    <Question message={message} />
  ) : (
    <div key={message.id} data-testid="message" className={`flexRowContainer pt15 pr15 pl15 pb10 ${message.role}`}>
      <div className="flexColumnContainer flexAlignCenter flexSpaceBetween mb8">
        <div
          className="flexColumnContainer flexAlignCenter"
          data-qtip-is-html="true"
          data-qtip-text={`${t('AI_ASSISTANT.AGENT_INFO', { agent: agent?.name })}<br/>${t(
            'AI_ASSISTANT.AGENT_SWITCH',
          )}`}>
          <Icon
            icon={agent?.icon || 'fa-sparkles'}
            testId="fc-genai-chat"
            extraClassNames="pl5 pr2"
            type="color"
            color={agent?.color}
          />
          {onAgentChange ? (
            <AgentSelector
              onAgentChange={onAgentChange}
              showAgentName={true}
              selectedAgentId={selectedAgent}
              showSelected={false}
              isRunning={isRunning}
            />
          ) : (
            <div className="pl5">{agent?.name}</div>
          )}
        </div>
        <div className="flexColumnContainer flexAlignCenter">
          <StatusUpdate
            stages={message.stages}
            isRunning={isRunning}
            isLast={last}
            isResponding={!_.isEmpty(message.dialog)}
          />
        </div>
      </div>

      <div className="flexRowContainer flexFill ml26">
        <MarkdownSnippet markdown={message.dialog} insertFormulaSnippet={insertFormulaSnippet} />
        <div>
          {isRunning && last && (
            <Icon icon="fa-circle fa-beat" iconPrefix="fa-solid" extraClassNames="ml5" type="dark-gray" small={true} />
          )}
          {!isRunning && last && (
            <Feedback
              onSubmitPrompt={submitPrompt}
              origin={origin}
              scrollToBottom={scrollToBottom}
              trackingContext={messages}
            />
          )}
        </div>
      </div>
    </div>
  );
};
